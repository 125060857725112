import React from 'react'

import Root from '../components/Root'

const Privacy = () => (
    <Root header="basic">
        <div className="max-w-6xl -mt-20 mx-auto px-8 md:px-12 lg:px-20 py-8 relative font-light text-black text-letft z-10">
            <div className="bg-white p-8 md:p-12 rounded-lg">
                <h1 className="max-w-lg mb-4 font-display font-bold text-xl md:text-2xl lg:text-3xl uppercase">
                    Marmalade Game Studio Privacy &amp; Cookie Policy
                </h1>
                <p className="mb-8 md:mb-12 font-bold uppercase">
                    Last updated: 29 July 2021
                </p>
                <p className="mt-4">
                    We are committed to respecting your privacy and protecting
                    your personal data. This privacy and cookie policy
                    (&ldquo;Policy&rdquo;) sets out how we use data relating to
                    you and applies to use of your personal data by Marmalade
                    Game Studio Limited, a company registered in England and
                    Wales with company number 03677408 and registered address 33
                    Charlotte Street, London, England, W1T 1RR
                    (&ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;).
                </p>
                <p className="mt-4">
                    This Policy sets out the basis on which personal data will
                    be processed by us. This Policy applies to personal data we
                    may collect from you, or that you provide to us, through or
                    in connection with the websites where we make this Policy
                    available (the &ldquo;Website&ldquo;). Please read the
                    Policy carefully to understand our views and practices
                    regarding your personal data.
                </p>
                <p className="mt-4">
                    We will only collect and process information about you in
                    accordance with this Policy and we will only use information
                    collected about you in accordance with applicable data
                    protection laws including the EU General Data Protection
                    Regulation 2016 (&ldquo;GDPR&rdquo;) and the UK Data
                    Protection Act 2018. Where we decide the purpose or means
                    for the processing of the personal data that you provide
                    when using our Website, we are the &ldquo;data
                    controller&rdquo; for the purposes of the GDPR.
                </p>
                <p className="mt-4">
                    You have the right to object to the processing of your
                    personal data, including where your personal data is being
                    processed for direct marketing purposes. Further information
                    on this right, and your other rights, is set out below.
                </p>
                <p className="mt-4">
                    If you have any concerns over privacy, or this Policy
                    contact us at{' '}
                    <a
                        href="mailto:contactus@marmalademail.com"
                        className="underline"
                    >
                        contactus@marmalademail.com
                    </a>
                    .
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    1. HOW WE COLLECT AND USE YOUR PERSONAL DATA
                </h2>
                <h3 className="mt-8 mb-4 font-display font-bold uppercase">
                    1.1. Data we collect, how we use it, and which recipients it
                    might be shared with.
                </h3>
                <ul className="list-disc">
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Support / enquiry information (information we
                            receive when you get in touch with us for support):
                            Name; Contact details (address, phone number,
                            email); and Other personal data you send to us. We
                            process this information in accordance with the
                            terms of our contract with you (where we need this
                            information to provide our services to you) and also
                            in pursuit of our legitimate interests, being our
                            business interests in responding to your enquiry,
                            maintaining good customer service and improving our
                            services. We use vendors: (i) Freshworks Inc. for
                            customer support software; and (ii) PartnerHero,
                            Inc. for managing our customer support.
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Website analytics (collected from your device and
                            interaction with our Website): how often you use our
                            Website; which pages and websites you go to /
                            arrived from; your IP address; your geolocation (at
                            a country and/or town/city level only);Technical
                            information about your device (e.g. device type,
                            browser, system, model, memory, wifi status,
                            timestamp and zone, language, mobile carrier);
                            Technical identifiers (e.g. advertiser identifier
                            numbers (IDFA), Google Advertiser ID, Android ID);
                            and Engagement and usage information (e.g. viewing
                            information, information about interactions and
                            usage events, engagement time). This processing is
                            necessary for us to pursue our legitimate interests,
                            being our business interests in understanding how
                            you use the Website, and to collect statistics, so
                            we might identify problems and also continually
                            improve the Website. We also process this
                            information in order to provide localisation of
                            aspects of our services. For example, your
                            geolocation (at a country level), may be processed
                            so that we can direct you to the appropriate first
                            party platform or store for the country relevant to
                            you. This processing is necessary for us to pursue
                            our legitimate interests of providing a
                            higher-quality, more tailored service and promoting
                            the success of our games. We use (1) Google
                            Analytics (Google LLC) to help us understand how our
                            Website is used.{' '}
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Email newsletters (the sign-ups where you can best
                            keep up-to-date with the latest Marmalade news and
                            offers): your email address; and other information
                            collected and clearly identifiable to you at the
                            point of your signing up to our email newsletter.
                            Consent-based mailing lists We may process your data
                            to make suggestions and recommendations to you
                            about: (i) news and updates relating to us, our
                            partners, products, services and content; (ii)
                            content, goods and services that may interest you;
                            and (iii) other promotional activity and events we
                            think may be of interest. To the extent required by
                            law, we rely upon your consent in order for us to
                            undertake this data processing activity. You can
                            revoke your consent at any time by letting us know,
                            including by emailing us. &lsquo;Soft opt-in&rsquo;
                            mailing lists In some instances (such as with some
                            of our existing customers) the law may permit us to
                            process your personal data in order to send
                            information to you by email that we think is of
                            interest for you on the basis of our legitimate
                            interests, being our legitimate business interests
                            in marketing our activities and promoting the
                            success of our business and our websites, content,
                            products and services. We may process your
                            information for this purpose. If the information is
                            not of interest for you, you can opt-out at any time
                            by letting us know, including by emailing us. We use
                            vendor MailChimp (The Rocket Science Group LLC) to
                            help us to administer our email newsletter databases
                            and to send out our newsletters.
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Competition and promotion related entry data (where
                            you may have entered a competition / prize promotion
                            with us (such as by web form, email, or over social
                            media)): your entry contact information (e.g. name,
                            email address, phone number, post code, social media
                            username or email address); information submitted as
                            part of your entry (e.g. user-generated
                            information); and information submitted so we can
                            process your entry (such as your address, if we need
                            to send you a prize). We may from time to time
                            operate prize promotions and competitions (without
                            any obligation to do so). If we do, your personal
                            data may be processed in connection with that
                            activity, if you choose to enter it. Your personal
                            data would be processed on the basis of performance
                            of a contract (being the competition / prize
                            promotion terms of entry). We use vendor MailChimp
                            (The Rocket Science Group LLC); and Fluid (Fluid
                            Digital Media Limited) to help us to process and
                            manage competition entries.
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Attribution &ndash; we may direct you (such as via a
                            link on our Website) to the service of an external
                            website. When this happens (such as when you click
                            the relevant link and are redirected), a limited
                            amount of information is processed. This may
                            include: Technical information about your device
                            (e.g. device type, browser, system, model, memory,
                            wifi status, timestamp and zone, language, mobile
                            carrier);Technical identifiers (e.g. advertiser
                            identifier numbers (IDFA), Google Advertiser ID,
                            Android ID);IP address; Engagement and usage
                            information (e.g. clicks on adverts, viewing
                            information, information about interactions and
                            usage events, engagement time);Link source or link
                            destination (e.g. where you arrived at our Website
                            from, or where you clicked from our Website to go
                            to). We process this information in order to
                            understand to which partners we directed you to from
                            our Website. We process this information on the
                            legal basis of our legitimate interests, being our
                            commercial interests in undertaking efficient
                            marketing activities and being able to identify
                            attribution, promoting the success of our business.
                            We use vendors such as (1) AppsFlyer (AppsFlyer UK
                            LTD and AppsFlyer Inc.); and (2) Google Tags
                            (Google, LLC) to help us to administer attribution /
                            affiliate link tracking.
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            The following information may be collected via the
                            Facebook Pixel on our Website: IP address;
                            Engagement and usage information (e.g. clicks on
                            adverts, viewing information, information about
                            interactions and usage events, engagement
                            time);Technical information about your device (e.g.
                            device type, browser, system, model, memory, wifi
                            status, timestamp and zone, language, mobile
                            carrier); andTechnical identifiers (e.g. advertiser
                            identifier numbers (IDFA), Facebook ID). We process
                            your information in this way for the purposes of our
                            targeted and re-targeted marketing activities. In
                            other words, so that we are able to provide you with
                            marketing and advertising that we think may be of
                            more interest for you, through Facebook&rsquo;s
                            services and sites. We conduct this processing on
                            the basis of our legitimate interests, being our
                            business interests in conducting more efficient
                            marketing which reaches the customers it is
                            potentially more interesting for and in order to
                            therefore promote the commercial success of our
                            games and services. We use the services of Facebook
                            (Facebook Ireland Ltd.) for this purpose.
                        </p>
                    </li>
                </ul>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    2.YOUR RIGHTS IN RELATION TO YOUR PERSONAL DATA
                </h2>
                <p className="mt-4">
                    2.1 You have certain rights over the way we process personal
                    data relating to you. We aim to comply without undue delay,
                    and within one month at the latest, in response to any
                    requests submitted by you to us:
                </p>
                <ul className="list-disc">
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            for a copy of personal data we are processing about
                            you and/or to have inaccuracies corrected;
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            to restrict, stop processing, or to delete your
                            personal data;
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            for a machine-readable copy of your personal data,
                            which you can use with another service provider.
                            Where it is technically feasible, you can ask us to
                            send this information directly to another provider
                            if you prefer; and
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            to make a complaint to a data protection regulator.
                            You may contact them at:
                            https://ico.org.uk/concerns/.
                        </p>
                    </li>
                </ul>
                <p className="mt-4">
                    2.2 To make a request in relation to any of the
                    aforementioned rights, please send your request to
                    <a
                        href="mailto:contactus@marmalademail.com"
                        className="underline"
                    >
                        contactus@marmalademail.com
                    </a>
                </p>
                <p className="mt-4">
                    2.3 You may request deletion of your personal data by
                    sending an e-mail to{' '}
                    <a
                        href="mailto:contactus@marmalademail.com"
                        className="underline"
                    >
                        contactus@marmalademail.com
                    </a>
                    . Please note that some information may remain in our
                    private records after deletion. We may use any aggregated
                    data derived from or incorporating your personal data but
                    not in any manner that would identify you personally.
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    3. DISCLOSURE OF YOUR PERSONAL DATA
                </h2>
                <p className="mt-4">
                    3.1 We will share your personal data with third parties only
                    in the ways that are described in this policy.
                </p>
                <p className="mt-4">
                    3.2 Group, suppliers, subcontractors, service providers We
                    keep your personal data confidential, but may disclose it to
                    any member of our corporate group, our personnel, suppliers
                    or subcontractors insofar as it is reasonably necessary for
                    the purposes set out in this Policy.
                </p>
                <p className="mt-4">
                    3.3 Government authorities. In addition, we may disclose
                    your personal data to the extent that we are required to do
                    so by law (which may include to government bodies and law
                    enforcement agencies); in connection with any legal
                    proceedings or prospective legal proceedings; and to
                    establish, exercise or defend our legal rights (including
                    providing information to others for the purposes of fraud
                    prevention).
                </p>
                <p className="mt-4">
                    3.4 Potential acquirers or investors. If we are involved in
                    a merger, acquisition, or sale of all or a portion of our
                    business or assets, the personal data we hold may be
                    included as part of that sale, in which case you will be
                    notified via email, your account and/or a prominent notice
                    on the website of any changes in ownership or use of your
                    personal data, as well as any choices you may have.
                </p>
                <p className="mt-4">
                    3.5 Enforcement. We may also disclose your personal data to
                    third parties in order to enforce or apply the terms of
                    agreements, to investigate potential breaches, or to protect
                    the rights, property or safety of us, our personnel or
                    customers, or others.
                </p>
                <p className="mt-4">
                    3.6 We only permit our suppliers and subcontractors to
                    process your personal data for specified purposes and in
                    accordance with our instructions. All our third-party
                    service providers are required to take appropriate security
                    measures to protect your personal data.
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    4. DATA RETENTION
                </h2>
                <p className="mt-4">
                    4.1 We will only hold data about you for as long as
                    necessary, bearing in mind the purpose for which that data
                    was collected, or as otherwise described in this Policy.
                </p>
                <p className="mt-4">
                    4.2. To determine the appropriate retention period for
                    personal data, we consider the amount, nature, and
                    sensitivity of the personal data, the potential risk of harm
                    from unauthorised use or disclosure of your personal data,
                    the purposes for which we process your personal data and
                    whether we can achieve those purposes through other means,
                    and the applicable legal requirements.
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    5. SECURITY
                </h2>
                <p className="mt-4">
                    5.1 We will take commercially reasonable, appropriate
                    technical and organisational measures to ensure a level of
                    security appropriate to the risk that could be encountered
                    via the use of our Website taking into account the
                    likelihood and severity those risks might pose to the rights
                    and freedoms of our Website visitors and customers.
                </p>
                <p className="mt-4">
                    5.2 In particular, we will take precautions to protect
                    against the accidental or unlawful destruction, loss or
                    alteration, and unauthorised disclosure of or access to the
                    personal data transmitted, stored or otherwise processed by
                    us.
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    6. INTERNATIONAL DATA TRANSFERS
                </h2>
                <p className="mt-4">
                    6.1 There are agreements in place to ensure that personal
                    data is processed using appropriate safeguards that meet the
                    requirements of data protection laws. Such appropriate
                    safeguards may include standard data protection clauses
                    adopted by a data protection regulator and approved by the
                    European Commission, such as the European Commission&rsquo;s
                    standard contractual clauses.
                </p>
                <p className="mt-4">
                    6.2 If you would like to find out more about these
                    safeguards or if you have any other queries or comments in
                    relation to this Policy, please let us know by emailing us
                    at{' '}
                    <a
                        href="mailto:contactus@marmalademail.com"
                        className="underline"
                    >
                        contactus@marmalademail.com
                    </a>
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    7. GENERAL
                </h2>
                <p className="mt-4">
                    7.1 If you follow a link from the Website to any third party
                    websites, you should be aware that those websites may have
                    their own privacy policies. We do not accept any
                    responsibility or liability for those websites. Please check
                    the policies of any third party websites before submitting
                    any personal data to those websites.
                </p>
                <p className="mt-4">
                    7.2 We may make changes to this Policy in the future, which
                    will be posted on this page. You should check this page from
                    time to time to ensure you are aware of any changes. Where
                    appropriate we may notify you of changes by email.
                </p>
                <p className="mt-4">
                    7.3 All Website privacy questions, comments or enquiries
                    should be directed to us. We will try to respond to you
                    within 48 hours or otherwise within a reasonable time.
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    CONTACT US
                </h2>
                <p className="mt-4">
                    E-mail:{' '}
                    <a
                        href="mailto:contactus@marmalademail.com"
                        className="underline"
                    >
                        contactus@marmalademail.com
                    </a>
                </p>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    COOKIE POLICY
                </h2>
                <p className="mt-4">
                    Cookies (and equivalent devices in the context of mobile
                    devices) are small text files that are placed on your
                    computer by websites that you visit. They are widely used in
                    order to make websites work, or work more efficiently, as
                    well as to provide information to the owners of the site.
                    The table below explains the cookies we use and why.
                </p>
                <ul className="list-disc">
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Google Analytics (for our Website). This Google LLC
                            cookie is used to collect information about how
                            visitors use our Website. We use the information to
                            compile reports and to help us improve our Website.
                            The cookies collect information in an anonymous
                            form, including the number of visitors to the
                            Website, where visitors have come to the Website
                            from and the pages they visited. Google has access
                            to this information, and uses it for reasons
                            including to be able to provide us with the Google
                            Analytics services and reports. You can find
                            Google&rsquo;s overview of privacy and safeguarding
                            data here. To opt-out of being tracked by Google
                            Analytics across all websites, visit the following
                            URL: http://tools.google.com/dlpage/gaoptout.
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Preferences. We may use this type of cookie to
                            remember information about you such as your
                            preferred language and configuration (where
                            applicable).
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Google Tags. We use this cookie / equivalent
                            technology so that we can measure and understand the
                            effectiveness of our marketing, by understanding
                            which of our partners lead you to us &ndash; and
                            also to which of our partners we lead you to (by
                            clicking links on our Website). Google Tags (Google,
                            LLC) has access to this information. You can find
                            more information about Google&rsquo;s privacy
                            practices here.
                        </p>
                    </li>
                    <li className="ml-4 pl-2">
                        <p className="mt-4">
                            Facebook Pixel. We may use Facebook Pixel for the
                            purposes of our targeted and re-targeted marketing
                            activities. In other words, so that we are able to
                            provide you with marketing and advertising that we
                            think may be of more interest for you, through
                            Facebook&rsquo;s services and sites. Facebook has
                            access to the information collected by the Facebook
                            Pixel. You can find more information about
                            Facebook&rsquo;s (Facebook Ireland Ltd.) privacy
                            practices here.
                        </p>
                    </li>
                </ul>
                <h2 className="mt-8 md:mt-12 mb-4 font-display font-bold text-lg md:text-xl uppercase">
                    HOW DO I CHANGE MY COOKIE SETTINGS
                </h2>
                <p className="mt-4">
                    Most web browsers allow some control of most cookies through
                    the browser settings. To find out more about cookies,
                    including how to see what cookies have been set, visit
                    www.aboutcookies.org.
                </p>
                <p className="mt-4">
                    To find information relating to browsers, visit the browser
                    developer&rsquo;s website.
                </p>
                <p className="mt-4">
                    Your mobile or tablet device provider may also provide you
                    with additional cookie and browser control options, which
                    may be available to you in your device settings &ndash;
                    please consult your device settings for such device-specific
                    controls (if applicable).
                </p>
            </div>
        </div>
    </Root>
)

export default Privacy
